<template>
    <v-card>
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="ml-n3 mr-2">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h2 class="font-weight-regular text-capitalize">
                {{ viewType }} Log
            </h2>
        </v-card-title>
        <v-divider class="mx-6" />
        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="items"
                class="elevation-0 mt-7"
                :mobile-breakpoint="0"
                disable-pagination
                hide-default-footer
            >
                <template v-slot:top>
                    <v-row
                        no-gutter
                        class="mt-0 mx-0 pt-4 pb-0 pb-4"
                        :style="{ 'background-color': '#eeeeee' }"
                    >
                        <v-col cols="6" class="d-flex align-center">
                            <h2
                                class="my-n3 d-flex align-center text-capitalize"
                            >
                                {{ viewType }} Entries
                            </h2></v-col
                        >
                    </v-row>
                </template>
                <!--ITEMS-->
                <template v-slot:[`item.index`]="{ index }">
                    <p class="my-0">
                        {{ index + 1 }}
                    </p>
                </template>
                <template v-slot:[`item.date`]="{ item }">
                    <div class="d-flex justify-center">
                        <p class="my-0">
                            {{
                                formatDate(
                                    item.date.seconds || item.date._seconds
                                )
                            }}
                        </p>
                    </div>
                </template>
                <!-- PRODUCTION -->
                <template v-slot:[`item.operator`]="{ item }">
                    <div>
                        <p class="my-0">{{ getUserName(item.operator) }}</p>
                    </div>
                </template>
                <template v-slot:[`item.process`]="{ item }">
                    <div>
                        <p class="my-0">{{ item.process }}</p>
                    </div>
                </template>
                <template v-slot:[`item.machine`]="{ item }">
                    <div>
                        <p class="my-0">{{ item.machine }}</p>
                    </div>
                </template>
                <template v-slot:[`item.qty`]="{ item }">
                    <div>
                        <p class="my-0">{{ item.qty }}</p>
                    </div>
                </template>
                <!-- DELIVERY -->
                <template v-slot:[`item.originProcess`]="{ item }">
                    <div class="d-flex justify-center">
                        <p class="my-0">
                            {{ item.originProcess }}
                        </p>
                    </div>
                </template>
                <template v-slot:[`item.originUser`]="{ item }">
                    <div class="d-flex justify-center">
                        <p class="my-0">
                            {{ getUserName(item.originUser) }}
                        </p>
                    </div>
                </template>
                <template v-slot:[`item.destinationProcess`]="{ item }">
                    <div class="d-flex justify-center">
                        <p class="my-0">
                            {{ item.destinationProcess }}
                        </p>
                    </div>
                </template>
                <template v-slot:[`item.destUser`]="{ item }">
                    <div class="d-flex justify-center">
                        <p class="my-0">
                            {{ getUserName(item.destUser) }}
                        </p>
                    </div>
                </template>
                <!-- QUALITY -->
                <template v-slot:[`item.qualityUser`]="{ item }">
                    <div class="d-flex justify-center">
                        <p class="my-0">
                            {{ getUserName(item.qualityUser) }}
                        </p>
                    </div>
                </template>
                <template v-slot:[`item.releaseQty`]="{ item }">
                    <div class="mb-5">
                        <p class="my-0">
                            {{ item.releaseQty }}
                        </p>
                    </div>
                </template>
                <template v-slot:[`item.reprocessQty`]="{ item }">
                    <div class="mb-5">
                        <p class="my-0">
                            {{ item.reprocessQty }}
                        </p>
                    </div>
                </template>
                <template v-slot:[`item.rejectQty`]="{ item }">
                    <div class="mb-5">
                        <p class="my-0">
                            {{ item.rejectQty }}
                        </p>
                    </div>
                </template>
                <template v-slot:[`item.NCcode`]="{ item }">
                    <div class="mb-5">
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <p class="my-0 text-center" v-on="on">
                                    {{ getNCCode(item.NCcode).code }}
                                </p>
                            </template>
                            <span>
                                Code:
                                {{ getNCCode(item.NCcode).code }}<br />
                                Type:
                                {{ getNCCode(item.NCcode).type }}<br />
                                Description:
                                {{ getNCCode(item.NCcode).description }}<br />
                            </span>
                        </v-tooltip>
                    </div>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import API from '@/services/api'
import moment from 'moment'
export default {
    name: 'ItemLog',
    props: {
        selectedItem: {
            type: Object,
            required: true,
        },
        users: {
            type: Array,
            required: true,
        },
        viewType: {
            type: String,
            required: true,
        },
    },

    data: () => ({
        headers: [],
        productionHeaders: [
            {
                text: 'ID',
                value: 'index',
                align: 'center',
                sortable: false,
                width: '50',
            },
            {
                text: 'DATE',
                value: 'date',
                align: 'center',
                sortable: false,
            },
            {
                text: 'USER',
                value: 'operator',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PROCESS',
                value: 'process',
                align: 'center',
                sortable: false,
                width: '100',
            },
            {
                text: 'MACHINE',
                value: 'machine',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'qty',
                align: 'center',
                sortable: false,
            },
        ],
        deliveryHeaders: [
            {
                text: 'ID',
                value: 'index',
                align: 'center',
                sortable: false,
                width: '50',
            },
            {
                text: 'DATE',
                value: 'date',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ORIGIN',
                value: 'originProcess',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DELIVERED BY',
                value: 'originUser',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESTINATION',
                value: 'destinationProcess',
                align: 'center',
                sortable: false,
            },
            {
                text: 'RECEIVED BY',
                value: 'destUser',
                align: 'center',
                sortable: false,
            },
            {
                text: 'LOCATION',
                value: 'locationItem',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'qty',
                align: 'center',
                sortable: false,
            },
        ],
        qualityHeaders: [
            {
                text: 'ID',
                value: 'index',
                align: 'center',
                sortable: false,
                width: '50',
            },
            {
                text: 'DATE',
                value: 'date',
                align: 'center',
                sortable: false,
            },
            {
                text: 'USER',
                value: 'qualityUser',
                align: 'center',
                sortable: false,
            },
            {
                text: 'RELEASED',
                value: 'releaseQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'REPROCESS',
                value: 'reprocessQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'REJECTED',
                value: 'rejectQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NC CODE',
                value: 'NCcode',
                align: 'center',
                sortable: false,
            },
        ],
        height: 0,
        items: [],
        ncCodes: [],
        packingProcess: {
            id: 'packing',
            name: 'packing',
        },
    }),

    async mounted() {
        if (this.viewType == 'production') {
            this.headers = this.productionHeaders
            this.setProductionItems()
        } else if (this.viewType == 'delivery') {
            this.headers = this.deliveryHeaders
            this.setDeliveryItems()
        } else if (this.viewType == 'quality') {
            this.headers = this.qualityHeaders
            this.setQualityItems()
        }

        const {
            data: { settings },
        } = await API.getSettings()
        const setting = settings.find(
            setting => setting.name.toLowerCase() == 'quality'
        )
        this.ncCodes = setting.ncCodes
        this.items.sort(
            (a, b) =>
                (b.date.seconds || b.date._seconds) -
                (a.date.seconds || a.date._seconds)
        )
        this.onResize()
    },
    methods: {
        close() {
            this.$emit('closeDialog')
        },

        onResize() {
            this.height = window.innerHeight - 260
        },
        setProductionItems() {
            this.items = []
            if (this.selectedItem.production) {
                this.selectedItem.production.forEach(entry => {
                    this.items.push({
                        date: entry.producedOn,
                        operator: entry.producedBy,
                        process: entry.process.name,
                        machine: entry.machine.name,
                        qty: entry.qty,
                    })
                })
            }
        },
        setDeliveryItems() {
            this.items = []
            if (this.selectedItem.deliveries) {
                let deliveries = this.selectedItem.deliveries
                if (this.$router.currentRoute.name == 'packing-balance') {
                    deliveries = deliveries.filter(
                        item =>
                            item.receivedBy &&
                            item.nextProcess.id == this.packingProcess.id
                    )
                }
                deliveries.forEach(entry => {
                    this.items.push({
                        date: entry.deliveredOn,
                        originProcess: entry.prevProcess.name,
                        originUser: entry.deliveredBy,
                        destinationProcess: entry.nextProcess.name,
                        destUser: entry.receivedBy,
                        qty: entry.qty,
                        locationItem: entry.locationItem,
                    })
                })
            }
        },
        setQualityItems() {
            this.items = []
            if (this.selectedItem.quality) {
                this.selectedItem.quality.forEach(entry => {
                    this.items.push({
                        date: entry.qualityReviewedOn,
                        qualityUser: entry.qualityReviewedBy,
                        releaseQty: entry.releaseQty || 0,
                        reprocessQty: entry.reprocessQty || 0,
                        rejectQty: entry.rejectQty || 0,
                        NCcode: entry.NCcode || '',
                    })
                })
            }
        },
        getUserName(id) {
            let userName = ''
            const user = this.users.find(u => u.id == id)
            if (user) {
                userName = user.name
            }
            return userName
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('YYYY-MM-DD HH:mm')
        },
        getNCCode(id) {
            let code = { code: id, type: id, description: id }
            const foundCode = this.ncCodes.find(c => c.id == id)
            if (foundCode) {
                code = foundCode
            }
            return code
        },
    },
}
</script>

<style></style>
